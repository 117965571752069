import axios from '@/config/httpConfig'
// 邮件已发送
export function emailSuccess(data) {
    return axios.post('/emailonlinepay/emailSuccess', data)
}
// 接口16 保存模板到文件
export function saveTemplatePdf(data) {
    return axios.post('/cpq/template/saveTemplatePdf', data)
}
// 接口17 预览生成的PDF文件
export function getTemplatePdf(data) {
    return axios.post('/cpq/template/getTemplatePdf', data)
}
// 接口19 获取报价单文件相关列表的所有文件
export function getFiles(data) {
    return axios.post('/cpq/template/getFiles', data)
}
